import React from "react";

import Login from "../../components/PageBlocks/AuthPage/Login";

const LoginPage = () => (
    <div className="login">
        <Login />
    </div>
);

LoginPage.getInitialProps = async function(context) {
    return {
        title: "Вход",
        description: "",
        keywords: "",
        pageClasses: "relative_wrapper login_page_wrapper",
        namespacesRequired: ["auth"]
    };
};

export default LoginPage;
