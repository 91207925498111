import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { emailRegExp, phoneRegExp, fullPhoneRegExp } from "../../HelperComponents/regExp";
import { sendRequest } from "../../HelperComponents/functions";
import { AppContext, API_BASE_URL } from "../../../context/appState";
import eye from "../../../public/images/pass-eye.svg";
import crossedEye from "../../../public/images/crossed-pass-eye.svg";
import Head from "next/head";

import "./AuthPage.scss";

import { withTranslation, Link, Router, i18n } from "../../../i18n";

const Login = ({ t }) => {
    const { language } = i18n;
    const [state, setState] = useState({
        origin: "password",
        double: "password"
    });
    const { register, errors, handleSubmit, setError, getValues } = useForm();
    const { patchCartStatus, resendEmail, dispatch } = useContext(AppContext);

    const hidePass = marker => {
        setState({ ...state, [marker]: state[marker] === "password" ? "text" : "password" });
    };

    useEffect(() => {
        console.log(errors.email);
    }, [language]);
    const submitForm = data => {
        const { email } = data;
        let values = { ...data };
        if (email.includes("@")) {
            if (emailRegExp.test(email)) {
                values.email = email;
            } else {
                setError("email", { type: "pattern", message: "Wrong email" });
            }
        } else if (email.includes("+")) {
            if (fullPhoneRegExp.test(email)) {
                delete values.email;
                values.phone_number = email;
            } else {
                setError("email", { type: "pattern", message: "Wrong phone number" });
            }
        } else {
            if (phoneRegExp.test(email)) {
                delete values.email;
                values.phone_number = `+38${email}`;
            } else {
                setError("email", { type: "pattern", message: "Wrong phone number" });
            }
        }
        if (Object.keys(errors).length == 0) {
            sendRequest(`${API_BASE_URL}/signin/`, "POST", values)
                .then(response => {
                    if (response.status === 200) {
                        response.json().then(data => {
                            localStorage.setItem("token", data.token);
                            //patchCartStatus(dispatch, "new");
                            Router.push(`/my/profile`);
                        });
                    } else {
                        response.json().then(data => {
                            Object.entries(data).forEach(err =>
                                setError(err[0], { type: "back_err", message: err[1] })
                            );
                        });
                    }
                })
                .catch(error => console.log(error.response.data));
        }
    };
    return (
        <div className="login_wrapper">
            <div className="auth_content">
                <Head>
                    <title>{t("Вход")} │ PRORAB</title>
                </Head>
                <div className="title_auth">{t("Вход")}</div>
                <form className="form_auth" onSubmit={handleSubmit(submitForm)}>
                    <div>
                        <span>{t("Введите email или номер мобильного")}</span>
                        <input
                            className={errors.email ? "red_border" : ""}
                            name="email"
                            ref={register({ required: true })}
                            type="text"
                        />
                        {errors.email && errors.email.type === "pattern" && (
                            <span className="error">{t("Неверный формат")}</span>
                        )}
                        {errors.email &&
                            errors.email.type === "back_err" &&
                            (errors.email.message[0] ===
                            "Ваша почта не подтверждена. Проверьте вашу почту. Мы выслали письмо для подтверждения." ? (
                                <div>
                                    <span className="error">
                                        {language === "ua"
                                            ? "Ваша пошта не підтверджена. Перевірте Вашу пошту. Ми вислали лист для підтвердження."
                                            : "Ваша почта не подтверждена. Проверьте вашу почту. Мы выслали письмо для подтверждения."}
                                        <span
                                            onClick={() => resendEmail(getValues("email"), dispatch)}
                                            style={{
                                                marginLeft: "5px",
                                                background: "transparent",
                                                display: "inline",
                                                cursor: "pointer"
                                            }}
                                        >
                                            {t("Отправить письмо еще раз")}
                                        </span>
                                    </span>
                                </div>
                            ) : (
                                <span className="error">{errors.email.message}</span>
                            ))}
                    </div>
                    <div>
                        <div className="password-wrap">
                            <span>{t("Пароль")}</span>
                            <span>
                                <Link href="/forgotpassword">
                                    <a>{t("Забыли пароль")}?</a>
                                </Link>
                            </span>
                        </div>

                        <input
                            name="password"
                            ref={register({ required: true, minLength: 8 })}
                            type={state.origin}
                            className={`pass_info ${errors.password ? "red_border" : ""}`}
                        />
                        <img
                            onClick={() => hidePass("origin")}
                            src={state.origin === "password" ? crossedEye : eye}
                            alt="eye"
                        />
                        {errors.password && errors.password.type === "minLength" && (
                            <span className="error">{t("Минимум 8 символов")}</span>
                        )}
                        {errors.password && errors.password.type === "back_err" && (
                            <span className="error">{errors.password.message}</span>
                        )}

                        <p
                            onClick={() => hidePass("origin")}
                            className={`${state.origin === "password" ? "active" : ""}`}
                        />
                    </div>
                    <button className="auth_btn">{t("Войти")}</button>
                    <div className="redirect-to-login">
                        <p>{t("Еще нет аккаунта?")}</p>
                        <Link href="/signup">
                            <a>{t("Зарегистироваться")}</a>
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default withTranslation("auth")(Login);
